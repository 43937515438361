import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["recipientNameField", "recipientIdField", "drawer", "achSection", "wireSection", "checkSection"];

  connect () {
    document.addEventListener("turbo:before-fetch-response", (event) => {
      if (this.paymentMethodIds().includes(event.srcElement.id)) {
        if (event.detail.fetchResponse.response.status === 200) {
          this.drawerTarget.hide();
        }
      }
    });
  }

  disconnect () {
    document.removeEventListener("turbo:before-fetch-response");
  }

  setRecipient ({ detail: { name, id } }) {
    this.recipientNameFieldTarget.innerHTML = name;
    this.recipientIdFieldTargets.forEach((idField) => {
      idField.value = id;
    });
  }

  selectAch () {
    this.achSectionTarget.classList.remove("hidden");
    this.wireSectionTarget.classList.add("hidden");
    this.checkSectionTarget.classList.add("hidden");
  }

  selectWire () {
    this.achSectionTarget.classList.add("hidden");
    this.wireSectionTarget.classList.remove("hidden");
    this.checkSectionTarget.classList.add("hidden");
  }

  selectCheck () {
    this.achSectionTarget.classList.add("hidden");
    this.wireSectionTarget.classList.add("hidden");
    this.checkSectionTarget.classList.remove("hidden");
  }

  paymentMethodIds () {
    return ["createCheckPaymentMethod", "createACHPaymentMethod", "createWirePaymentMethod"];
  }
}
