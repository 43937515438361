import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton", "verificationCode"];

  fillPlaceholder () {
    const classList = this.verificationCodeTarget.classList;
    this.verificationCodeTarget.value ? classList.add("filled") : classList.remove("filled");
  }

  submit () {
    this.submitButtonTarget.disabled = true;
  }
}
