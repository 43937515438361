import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["frameWrapper"];

  onShow () {
    this.frameWrapperTarget.classList.add("shown");
  }

  onHide () {
    this.frameWrapperTarget.classList.remove("shown");
  }
}
