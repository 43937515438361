import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["drawer", "rejectForm", "actionButtons", "formInput", "form", "text"];

  onShow () {
    this.element.classList.add("shown");
  }

  onHide () {
    this.element.classList.remove("shown");
  }

  inputFocus () {
    if (this.hasFormInputTarget) {
      this.formInputTarget.focus();
    }
  }

  animateToShowForm () {
    this.fadeIn(this.rejectFormTarget);
    this.fadeOut(this.actionButtonsTarget);
    this.inputFocus();
  }

  animateToHideForm () {
    this.fadeOut(this.rejectFormTarget);
    this.fadeIn(this.actionButtonsTarget);
  }

  fadeIn (element) {
    element.classList.remove("fade-out", "hidden");
    element.classList.add("fade-in");
  }

  fadeOut (element) {
    element.classList.remove("fade-in");
    element.addEventListener("transitionend", function () {
      this.classList.add("hidden");
    }, { once: true });
    element.classList.add("fade-out");
  }

  hideForm () {
    this.formTarget.classList.add("hidden");
    this.textTarget.classList.remove("hidden");
  }
}
