import { Controller } from "@hotwired/stimulus";

const emailSessionStorageKey = "enteredEmail";

export default class extends Controller {
  connect () {
    const email = sessionStorage.getItem(emailSessionStorageKey);
    if (email) {
      this.element.value = email;
      sessionStorage.removeItem(emailSessionStorageKey);
    }
  }
}
