export default class PaymentAmountRadio {
  static FADE_IN = ".fade-in";
  static OTHER_INPUT = "other-input";
  static SHOW = "show";

  constructor (element) {
    this.element = element;
    this.fadeIn = element.querySelector(PaymentAmountRadio.FADE_IN);
  }

  setSelected (selected) {
    if (selected) {
      this.show();
    } else {
      this.hide();
    }
  }

  show () {
    if (this.fadeIn) {
      this.fadeIn.classList.add(PaymentAmountRadio.SHOW);
    }
  }

  hide () {
    if (this.fadeIn) {
      this.fadeIn.classList.remove(PaymentAmountRadio.SHOW);
    }
  }
}
