import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect () {
    this.element.addEventListener("turbo:submit-start", this.disable.bind(this));
  }

  disconnect () {
    this.element.removeEventListener("turbo:submit-start");
  }

  disable () {
    const btn = this.element.querySelector("sl-button[type=submit]");
    btn.setAttribute("disabled", "disabled");
    btn.textContent = "Processing...";
  }
}
