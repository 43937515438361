import Details from "./details";
import PaymentAmountRadio from "./payment_amount_radio";

export class MakeAPayment {
  static RADIO_GROUP = "sl-radio-group";
  static OTHER = "other";
  static OTHER_VALUE_INPUT = "sl-input[name=other]";

  constructor (view) {
    this.view = view;
  }

  select (detailsEventTarget) {
    const currentOrder = detailsEventTarget.dataset.order;
    this.view.detailsTargets.forEach(detailsTarget => {
      const targetOrder = detailsTarget.dataset.order;
      const details = new Details(detailsTarget);

      if (targetOrder < currentOrder) {
        details.setCompleted(true);
      } else {
        details.setSelected(detailsEventTarget === detailsTarget);
      }
      this.updateReviewValues();
    });

    this.toggleNextButtons();
  }

  next (event) {
    const selectedDetails = this.detailsFromButtonEvent(event);
    selectedDetails.setCompleted(true);
    this.select(this.nextDetails(selectedDetails));
  }

  back (event) {
    const selectedDetails = this.detailsFromButtonEvent(event);
    selectedDetails.setCompleted(false);
    this.select(this.previousDetails(selectedDetails));
  }

  input (event) {
    if (event.target.value <= 0) {
      this.view.nextButtonTarget.removeAttribute("data-action");
    } else {
      this.view.nextButtonTarget.setAttribute("data-action", "make-a-payment#onNext");
    }
  }

  nextDetails (currentDetails) {
    return this.view.detailsTargets[this.indexFromDetails(currentDetails) + 1];
  }

  previousDetails (currentDetails) {
    return this.view.detailsTargets[this.indexFromDetails(currentDetails) - 1];
  }

  toggleNextButtons () {
    const dollarAmount = this.view.reviewAmountTarget.innerHTML;
    const numberAmount = Number(dollarAmount.replace(/[^0-9.]+/g, ""));

    this.view.nextButtonTargets.forEach(nextButtonTarget => {
      if (numberAmount <= 0) {
        nextButtonTarget.disabled = true;
        nextButtonTarget.removeAttribute("data-action");
      } else {
        nextButtonTarget.disabled = false;
        nextButtonTarget.setAttribute("data-action", "make-a-payment#onNext");
      }
    });
  }

  selectRadio (event) {
    this.toggleNextButtons();

    if (event === undefined) {
      return;
    }

    if (event.target.classList.contains("other-input")) {
      return;
    }

    this.view.selectAmountRadiosTargets.forEach(radioTarget => {
      const radio = new PaymentAmountRadio(radioTarget);
      radio.setSelected(radioTarget === event.target);
    });
    const amountType = event.target.getAttribute("value");
    this.updateAmount(amountType);

    this.toggleNextButtons();
  }

  selectDate () {
    this.updateDate();
  }

  indexFromDetails (currentDetails) {
    return this.view.detailsTargets.indexOf(currentDetails.element);
  }

  detailsFromButtonEvent (event) {
    return new Details(event.target.parentNode.parentNode);
  }

  updateReviewValues () {
    const amountType = document.querySelector(MakeAPayment.RADIO_GROUP).value;
    this.updateAmount(amountType);
    this.updateDate();
  }

  updateDate () {
    this.view.reviewDateTargets.forEach(dateTarget => {
      const paymentDate = new Date(this.view.inputDateTarget.value);
      dateTarget.innerHTML = paymentDate.toLocaleString("en-US", { day: "2-digit", year: "numeric", month: "numeric", timeZone: "UTC" });
    });
  }

  updateAmount (amountType) {
    this.view.reviewAmountTargets.forEach(amountTarget => {
      amountTarget.innerHTML = this.getAmount(amountType);
    });
  }

  getAmount (amountType) {
    if (amountType === MakeAPayment.OTHER) {
      return this.formatToMoney(this.otherAmount());
    } else {
      return document.querySelector("input[name=" + amountType + "]").value;
    }
  }

  otherAmount () {
    return document.querySelector(MakeAPayment.OTHER_VALUE_INPUT).value;
  }

  formatToMoney (amount) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return formatter.format(amount);
  }
}
