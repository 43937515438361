import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "approveModal",
    "rejectModal",
    "approvalForm",
    "rejectionForm",
    "rejectionReasons",
    "selectError",
  ];

  openApprove () {
    this.approveModalTarget.show();
  }

  openReject () {
    this.rejectModalTarget.show();
  }

  closeApprove () {
    this.approveModalTarget.hide();
  }

  closeReject () {
    this.rejectModalTarget.hide();
  }

  submitApprove () {
    this.approvalFormTarget.submit();
  }

  submitReject () {
    if (this.rejectionReasonsTarget.value.length === 0) {
      this.selectErrorTarget.classList.remove("hidden");
    } else {
      this.rejectionFormTarget.submit();
    }
  }
}
