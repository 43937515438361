import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "checkboxContainer", "submitButton"];

  enableSubmit () {
    this.submitButtonTarget.disabled = false;
  }

  toggleViewSubmit () {
    this.submitButtonTarget.hidden = !this.checkboxContainerTarget.checked;
  }

  submitForm () {
    this.formTarget.requestSubmit();
    this.checkboxContainerTarget.classList.add("hidden");
    this.submitButtonTarget.hidden = true;
  }
}
