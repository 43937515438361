import { Controller } from "@hotwired/stimulus";
import { loadConnectAndInitialize } from "@stripe/connect-js";

export default class extends Controller {
  static targets = ["stripe"];

  stripeTargetConnected () {
    this.initializeStripe();
  }

  initializeStripe = async () => {
    const publishableKey = this.stripeTarget.dataset.publishableKey;
    const clientSecretUrl = this.stripeTarget.dataset.secretUrl;

    if (!publishableKey) {
      console.error("Missing publishable key");
      return;
    }

    if (!clientSecretUrl) {
      console.error("Missing client secret url");
      return;
    }

    this.stripe = loadConnectAndInitialize({
      publishableKey,
      fetchClientSecret: this.fetchClientSecret,
    });

    // https://docs.stripe.com/connect/supported-embedded-components/account-onboarding
    this.stripeAccountOnboarding = this.stripe.create("account-onboarding");

    this.stripeAccountOnboarding.setCollectionOptions({
      fields: "eventually_due",
      futureRequirements: "include",
    });

    // Send to processing page to determine next steps
    this.stripeAccountOnboarding.setOnExit(() => {
      window.location.href = this.stripeTarget.dataset.onboardingExitUrl;
    });

    this.stripeTarget.appendChild(this.stripeAccountOnboarding);
  };

  // Stripe calls this initially and then again after the client secret expires in long running sessions
  fetchClientSecret = async () => {
    const response = await fetch(this.stripeTarget.dataset.secretUrl);
    const data = await response.json();
    return data.stripe_client_secret;
  };
}
