import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["availableBalance", "amount", "amountWarning"];

  connect () {
    this.updateAmount();
  }

  updateAmount () {
    if (this.amountTarget.value > (this.availableBalanceTarget.value * 0.01)) {
      this.amountTarget.classList.add("error");
      this.amountWarningTarget.classList.remove("hidden");
    } else {
      this.amountTarget.classList.remove("error");
      this.amountWarningTarget.classList.add("hidden");
    }
  }
}
