import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["statementsPanel"];

  viewAll () {
    this.statementsPanelTarget.show();
  }

  onLeave () {
    this.statementsPanelTarget.hide();
  }
}
