import Teamshares from "@teamshares/design-system/rails-js";

import { datadogRum } from "@datadog/browser-rum";

Teamshares.start();

// Set up error handler
Teamshares.stimulusApplication.handleError = (error, message, detail) => {
  console.warn(message, detail);
  window.Honeybadger && window.Honeybadger.notify(error);
};

if (window._ddSessionReplayEnabled === true) {
  datadogRum.init({
    applicationId: window._ddApplicationId,
    clientToken: window._ddClientToken,
    site: window._ddSite,
    service: window._ddService,
    env: window._ddEnvironment,

    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: parseInt(window._ddSessionSampleRate),
    premiumSampleRate: parseInt(window._ddPremiumSampleRate),
    trackUserInteractions: !!window._ddTrackUserInteractions,
    defaultPrivacyLevel: window._ddPrivacyLevel,
  });

  datadogRum.startSessionReplayRecording();
}

// Utility function to determine whether the user is on a mobile device using a few methods
window.isUserOnMobile = function () {
  // User agent string method
  let isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  // Screen resolution method
  if (!isMobile) {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    isMobile = screenWidth < 768 || screenHeight < 768;
  }

  // Touch events method
  if (!isMobile) {
    isMobile =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;
  }

  // CSS media queries method
  if (!isMobile) {
    const bodyElement = document.getElementsByTagName("body")[0];
    isMobile =
      window
        .getComputedStyle(bodyElement)
        .getPropertyValue("content")
        .indexOf("mobile") !== -1;
  }

  return isMobile;
};
