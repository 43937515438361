import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  doLogout () {
    window.location = "/session_timeout";
  }

  scheduleNextCheck (ms) {
    setTimeout(() => {
      this.checkTimeout();
    }, ms);
  }

  checkTimeout () {
    return fetch("/check_session_timeout")
      .then(response => response.json())
      .then(timeoutSeconds => {
        if (timeoutSeconds > 0) {
          this.scheduleNextCheck(timeoutSeconds * 1000);
        } else {
          this.doLogout();
        }
      })
      .catch(err => {
        window.Honeybadger && window.Honeybadger.notify("Error trying to retrieve session timeout" + err);
      });
  }

  connect () {
    this.checkTimeout();
  }
}
