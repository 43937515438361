import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "text"];

  hideForm () {
    this.formTarget.classList.add("hidden");
    this.textTarget.classList.remove("hidden");
  }
}
