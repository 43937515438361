import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static filled = "filled";

  static targets = ["email", "continue", "flashMessage", "defaultSignIn", "defaultSignInForm", "redirectMessage"];

  static values = {
    autoSubmit: Boolean,
  };

  connect () {
    this.fillPlaceholder();
    this.autoSubmit();
  }

  submit (e) {
    if (e) e.preventDefault();

    this.continueTarget.disabled = true;
    this.defaultSignInFormTarget.submit();
  }

  fillPlaceholder () {
    const classList = this.emailTarget.classList;
    const filled = this.constructor.filled;

    this.emailTarget.value ? classList.add(filled) : classList.remove(filled);
  }

  autoSubmit () {
    if (this.autoSubmitValue && this.emailTarget.value) {
      this.defaultSignInTarget.classList.add("hidden");
      this.redirectMessageTarget.classList.remove("hidden");
      this.flashMessageTarget.classList.add("hidden");
      setTimeout(() => this.submit(), 200);
    }
  }
}
