import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["drawer", "closeDrawer"];

  closeDrawerTargetConnected () {
    if (this.hasDrawerTarget) {
      this.drawerTarget.hide();
    }
  }
}
