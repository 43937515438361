import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["paymentCancellationConfirmation"];

  connect () {}

  showPaymentCancellationConfirmation () {
    this.paymentCancellationConfirmationTarget.show();
  }

  hidePaymentCancellationConfirmation () {
    this.paymentCancellationConfirmationTarget.hide();
  }
}
