import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="select-transfer-type"
export default class extends Controller {
  static targets = [
    "nextButton",
  ];

  connect () {
    this.nextButtonTarget.disabled = true;
  }

  onSelectTransferType (event) {
    if (event.target.value && event.target.value !== "") {
      this.nextButtonTarget.disabled = false;
    } else {
      this.nextButtonTarget.disabled = true;
    }
  }
}
