import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menuItem"];

  connect () {
    this.menuItemTargets.forEach(item => {
      item.addEventListener("click", this.handleMenuItemClick.bind(this));
    });
  }

  getDialogElement (dialogId) {
    return this.element.querySelector(`#${dialogId}`);
  }

  handleMenuItemClick (event) {
    const action = event.target.dataset.action;
    const dialogId = event.target.dataset.dialogId;
    const dialog = this.getDialogElement(dialogId);

    if (action === "show") {
      dialog.show();
    } else if (action === "hide") {
      dialog.hide();
    }
  }
}
