import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["amount", "amountType", "amountWrapper"];

  connect () {
    this.toggleAmount();
  }

  // Wrapper HTML adds data attributes to both wrapper div and sl-radiogroup; use the latter
  amountTypeValue = () => this.amountTypeTargets.map(target => target.value).find(value => value);

  toggleAmount () {
    if (this.amountTypeValue() === "fixed_amount") {
      this.unhideAmount();
    } else {
      this.hideAmount();
    }
  }

  unhideAmount () {
    if (!this.amountTarget.value) this.amountTarget.value = "0.00";
    this.amountWrapperTarget.classList.remove("hidden");
  }

  hideAmount () {
    this.amountWrapperTarget.classList.add("hidden");
    this.amountTarget.value = "0.00";
    this.clearAmountError();
  }

  clearAmountError () {
    const fieldWithErrors = this.amountWrapperTarget.querySelector(".field_with_errors");
    if (fieldWithErrors) fieldWithErrors.classList.remove("field_with_errors");

    const errorMessage = this.amountWrapperTarget.querySelector(".error.ts-input__help");
    if (errorMessage) errorMessage.classList.add("hidden");
  }
}
