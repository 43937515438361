import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["spinner"];
  connect () {
    this.element.addEventListener("turbo:frame-load", () => {
      if (this.spinnerTarget) {
        this.removeSpinner();
      }
    });
  }

  removeSpinner () {
    this.spinnerTarget.classList.add("hidden");
  }
}
