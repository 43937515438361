import { Controller } from "@hotwired/stimulus";
import { SendMoney } from "../presenters/send_money/send_money";

export default class extends Controller {
  static targets = [
    "form",
    "details",
    "recipient",
    "reviewRecipient",
    "paymentMethod",
    "paymentMethodOptions",
    "paymentMethodCustomerId",
    "paymentMethodId",
    "paymentMethodType",
    "reviewPaymentMethod",
    "reviewPaymentDelivery",
    "amount",
    "reviewAmount",
    "reviewAmountSubtext",
    "memo",
    "externalMemoArea",
    "reviewExternalMemo",
    "reviewExternalMemoArea",
    "note",
    "reviewNote",
    "dateContainer",
    "dateType",
    "dateOneTimeContainer",
    "dateOneTimeOptions",
    "dateOneTimePicker",
    "dateRecurringContainer",
    "dateRecurringFrequency",
    "dateRecurringStartPicker",
    "dateRecurringFirstAndFifteenthStartDay",
    "dateRecurringEndOptions",
    "dateRecurringEndPicker",
    "dateRecurringFirstAndFifteenthEndDay",
    "dateNonBankingDayNotice",
    "dateRecurringReapprovalNotice",
    "reviewDateFrequency",
    "reviewDateFrequencyCustom",
    "reviewDateTerm",
    "paymentDrawer",
    "recipientDrawer",
    "availableBalance",
    "weeklyTransfersNotice",
    "cancellationNotice",
    "oneTimeExpirationNotice",
  ];

  connect (sendMoney) {
    this.send_money = sendMoney || new SendMoney(this);
    this.send_money.updateReviewValues();

    // Wait for all controllers to connect before opening the first incomplete step
    document.addEventListener("DOMContentLoaded", () => {
      this.send_money.openFirstIncompleteStep();
    });
  }

  disconnect () {}

  updateReviewTargetConnected () {
    this.send_money.updateReviewValues();
  }

  onSelect (event) {
    if (event.target.classList.contains("accordion-details")) {
      switch (event.target.getAttribute("data-name")) {
        case "recipient":
          this.send_money.select(event.target);
          break;
        case "payment-method":
          if (this.recipientTarget.value) {
            this.send_money.select(event.target);
          } else {
            event.preventDefault();
          }
          break;
        case "amount":
          if (this.recipientTarget.value && this.paymentMethodTarget.value) {
            this.send_money.select(event.target);
          } else {
            event.preventDefault();
          }
          break;
        case "date_frequency":
          if (this.recipientTarget.value && this.paymentMethodTarget.value) {
            this.send_money.select(event.target);
          } else {
            event.preventDefault();
          }
          break;
        case "review":
          if (
            this.recipientTarget.value &&
            this.paymentMethodTarget.value &&
            this.amountTarget.value
          ) {
            this.send_money.select(event.target);
          } else {
            event.preventDefault();
          }
          break;
      }
    }
  }

  onDeselect (event) {
    if (event.target.dataset.selected === "true") {
      event.preventDefault();
      event.target.closest("sl-animation").play = true;
    }
  }

  onNext (event) {
    this.send_money.next(event.target);
  }

  onBack (event) {
    this.send_money.back(event.target);
  }

  // Step detail helpers

  // Recipient
  recipientTargetConnected (target) {
    // Wait for the sl-select webcomponent microtasks to complete before selecting the recipient
    Promise.resolve().then(() => {
      new SendMoney(this).selectRecipient(target);
    });
  }

  onRecipientChange (event) {
    this.send_money.selectRecipient(event.target);
  }

  onRecipientCreate (event) {
    event.preventDefault();
    this.recipientDrawerTarget.show();
  }

  // Payment Method
  paymentMethodTargetConnected () {
    new SendMoney(this).updateReviewValues();
  }

  onAddNewPaymentMethod (event) {
    event.preventDefault();
    this.paymentDrawerTarget.show();
  }

  onPaymentMethodChange (event) {
    this.send_money.selectPaymentMethod(event.target);
  }

  // Amount
  onAmountChange () {
    this.send_money.setAmount();
  }

  onMemoChange () {
    this.send_money.setMemo();
  }

  onNoteChange () {
    this.send_money.setNote();
  }

  // Date
  dateTypeTargetConnected () {
    // Wait for the sl-select webcomponent microtasks to complete before auto-selecting the date type
    Promise.resolve().then(() => this.onDateChange());
  }

  onDateChange () {
    this.send_money.setDateType();
    this.send_money.configureDateOptions();
  }

  // Form
  preventDefault (event) {
    event.preventDefault();
  }
}
