import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sendButton", "requiredCheckbox"];

  connect () {
    console.log("required_checkbox_controller");
  }

  toggleButton () {
    this.sendButtonTarget.disabled = !this.requiredCheckboxTarget.checked;
  }
}
