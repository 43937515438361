import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["pageContent"];

  connect () {
    if (!window.isUserOnMobile()) {
      window.location.href = this.data.get("url-value");
    } else {
      this.pageContentTarget.classList.remove("hidden");
    }
  }
}
