import { Controller } from "@hotwired/stimulus";
import { MakeAPayment } from "../presenters/payments/make_a_payment";
import Details from "../presenters/payments/details";
import PaymentAmountRadio from "../presenters/payments/payment_amount_radio";

export default class extends Controller {
  static targets = ["details", "selectAmountRadios", "inputDate", "inputAmount", "otherValue", "reviewDate", "reviewAmount", "nextButton"];

  connect () {
    const amountDetails = new Details(this.detailsTargets[0]);
    amountDetails.setSelected(true);
    new MakeAPayment(this).updateReviewValues();

    const statementBalanceRadio = new PaymentAmountRadio(this.selectAmountRadiosTargets[0]);
    statementBalanceRadio.setSelected(true);
    this.onSelectRadio();
  }

  stopPropagation (event) {
    event.stopPropagation();
  }

  onSelect (event) {
    new MakeAPayment(this).select(event.target);
  }

  onSelectRadio (event) {
    new MakeAPayment(this).selectRadio(event);
  }

  onDateSelect () {
    new MakeAPayment(this).selectDate();
  }

  onNext (event) {
    new MakeAPayment(this).next(event);
  }

  onBack (event) {
    new MakeAPayment(this).back(event);
  }

  onInput (event) {
    new MakeAPayment(this).input(event);
  }
}
