import { Controller } from "@hotwired/stimulus";

const emphasizeError = (element) => {
  element.shadowRoot
    .querySelector(".input--standard")
    .setAttribute("style", "border: 1px solid rgb(215 53 28)");
};

export default class extends Controller {
  static targets = [
    "amount",
    "amountErrorRiskFactorList",
    "canvasFront",
    "canvasBack",
    "cameraInputFront",
    "cameraInputBack",
    "frontLabel",
    "backLabel",
    "checkForm",
    "checkProcessing",
    "error",
  ];

  // Can comment out to test the flow on desktop if need be
  initialize () {
    if (
      !window.isUserOnMobile() &&
      Teamshares.deploy_context === "production"
    ) {
      window.location = "/dashboard";
    }
  }

  formatAmount () {
    const value = this.amountTarget.valueAsNumber;

    // Check if the value is not NaN and is a number
    if (!isNaN(value)) {
      // Convert the number to a string with two decimal places
      this.amountTarget.value = value.toFixed(2);
    }
  }

  selectAmount () {
    this.amountTarget.focus();
    this.amountTarget.select();
  }

  amountErrorRiskFactorListTargetConnected () {
    if (this.amountErrorRiskFactorListTarget.children.length > 0) {
      setTimeout(() => {
        emphasizeError(this.amountTarget);
      }, 50);
    }
  }

  displayFrontImage (event) {
    this.displayImage(event, this.canvasFrontTarget, this.frontLabelTarget);
  }

  displayBackImage (event) {
    this.displayImage(event, this.canvasBackTarget, this.backLabelTarget);
  }

  displayImage (event, canvas, label) {
    const file = event.target.files[0];
    const ctx = canvas.getContext("2d");
    const image = new Image();
    label.textContent = file.name;

    image.src = URL.createObjectURL(file);
    image.onload = () => {
      // Get the card's dimensions
      const cardWidth = canvas.parentElement.offsetWidth;
      const cardHeight = canvas.parentElement.offsetHeight;

      // Determine if the image needs to be rotated
      const shouldRotate = image.height > image.width;

      canvas.width = cardWidth;
      canvas.height = cardHeight;

      if (shouldRotate) {
        ctx.translate(cardWidth / 2, cardHeight / 2);
        ctx.rotate(Math.PI / 2);
        ctx.drawImage(
          image,
          -cardHeight / 2,
          -cardWidth / 2,
          cardHeight,
          cardWidth,
        );
      } else {
        ctx.drawImage(image, 0, 0, cardWidth, cardHeight);
      }

      canvas.classList.remove("hidden");
      canvas.classList.add("rounded");
      label.classList.add("hidden"); // Hide the label once the image is displayed
      canvas.addEventListener("click", () => label.click());

      URL.revokeObjectURL(image.src); // Revoke the object URL to free up resources
    };
  }

  submitCheck () {
    this.checkFormTarget.classList.add("hidden");
    if (this.hasErrorTarget) this.errorTarget.classList.add("hidden");
    this.checkProcessingTarget.classList.remove("hidden");
  }
}
