import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  onVisibilityChange () {
    if (document.visibilityState === "visible") {
      this.reloadForSessionChanges();
    }
  }

  onWindowFocus () {
    this.reloadForSessionChanges();
  }

  reloadForSessionChanges () {
    fetch("/auth_zero_session_changes/new")
      .then(response => response.json())
      .then(({ sessionChanged }) => { if (sessionChanged) window.location.reload(); })
      .catch(err => {
        window.Honeybadger && window.Honeybadger.notify("Error trying to retrieve session changes: " + err);
      });
  }
}
