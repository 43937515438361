import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["expandedMainAccountNumber", "expandedInboundAccountNumber"];

  connect () {
    const accountNumberElement = this.hasExpandedMainAccountNumberTarget ? this.expandedMainAccountNumberTarget : this.expandedInboundAccountNumberTarget;

    const path = this.hasExpandedMainAccountNumberTarget ? "expand_main_account_number" : "expand_inbound_account_number";

    fetch(`/financial_accounts/${path}`)
      .then(response => response.text())
      .then((expandedAccountNumber) => {
        accountNumberElement.innerHTML = expandedAccountNumber;
      });
  }
}
