import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["drawer", "resetDrawer"];

  // the reset drawer targets are returned in turbostream responses along with the export confirmation dialog
  // for a successful export request
  // and those elements appearing then triggers the export drawer to close
  resetDrawerTargetConnected () {
    this.drawerTarget.hide();
  }
}
