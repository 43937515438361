import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["expandedAccountNumber", "accountNumberLastFour"];

  hide () {
    this.accountNumberLastFourTargets.forEach(element => {
      if (element.classList.contains("hidden")) {
        element.classList.remove("hidden");
        this.expandedAccountNumberTargets.forEach(el => {
          el.classList.add("hidden");
        });
      }
    });
  }
}
