import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["exportConfirmation"];

  connect () {
    this.showExportConfirmation();
  }

  showExportConfirmation () {
    this.exportConfirmationTarget.show();
  }

  hideExportConfirmation () {
    this.exportConfirmationTarget.hide();
  }
}
