import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["export", "customDateRange", "fromDate", "toDate", "fromDateInput", "toDateInput", "dateError"];

  connect () {
    this.dateRange = "yesterday";
  }

  hideDateRange (event) {
    this.dateRange = event.target.value;
    this.customDateRangeTarget.classList.add("hidden");
    this.exportTarget.disabled = false;
  }

  showDateRange (event) {
    this.dateRange = event.target.value;
    this.customDateRangeTarget.classList.remove("hidden");
    this.checkDateRange();
  }

  updateFromDate (event) {
    this.fromDateTarget.value = event.target.value;
    this.checkDateRange();
  }

  updateToDate (event) {
    this.toDateTarget.value = event.target.value;
    this.checkDateRange();
  }

  checkDateRange () {
    if (!this.fromDateInputTarget.value || !this.toDateInputTarget.value) {
      this.exportTarget.disabled = true;
    } else {
      if (this.validateInput()) {
        this.dateErrorTarget.classList.add("hidden");
        this.exportTarget.disabled = false;
      } else {
        this.exportTarget.disabled = true;
      }
    }
  }

  validateInput () {
    let valid = true;

    if (parseInt(this.fromDateTarget.value.replace(/-/g, "")) > parseInt(this.toDateTarget.value.replace(/-/g, ""))) {
      this.dateErrorTarget.classList.remove("hidden");
      valid = false;
    }

    return valid;
  }

  calculateYesterday () {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return yesterday.toISOString().split("T")[0];
  }

  getDateParameters () {
    const fromDate = document.createElement("input");
    const toDate = document.createElement("input");

    fromDate.setAttribute("name", "from_date");
    toDate.setAttribute("name", "to_date");
    if (this.dateRange === "custom") {
      fromDate.setAttribute("value", this.fromDateTarget.value);
      toDate.setAttribute("value", this.toDateTarget.value);
    } else if (this.dateRange === "yesterday") {
      fromDate.setAttribute("value", this.calculateYesterday());
      toDate.setAttribute("value", this.calculateYesterday());
    }
    return [fromDate, toDate];
  }

  export () {
    const form = document.createElement("form");

    if (this.data.get("transaction-type") === "CashAccount") {
      form.action = "/transactions/export_qbo";
    } else if (this.data.get("transaction-type") === "ChargeCard") {
      form.action = "/charge_cards/export_qbo";
    }

    form.method = "POST";
    form.style.display = "none";

    const exportDates = this.getDateParameters();
    form.appendChild(exportDates[0]);
    form.appendChild(exportDates[1]);
    document.body.appendChild(form);

    form.requestSubmit();
  }
}
