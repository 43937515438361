import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["detailDrawer", "detailTurboframe", "note", "form"];

  connect () {
    if (this.data.get("open-href") !== null) {
      this.detailTurboframeTarget.src = this.data.get("open-href");
      this.detailDrawerTarget.show();
    }
  }

  onShow (event) {
    this.detailTurboframeTarget.src = event.currentTarget.dataset.href;
    this.detailDrawerTarget.show();
  }

  hideForm () {
    this.formTarget.classList.add("hidden");
  }

  showNoteData () {
    this.hideForm();
    this.noteTarget.classList.remove("hidden");
  }
}
