import { Controller } from "@hotwired/stimulus";
import QRCode from "qrcode";

export default class extends Controller {
  static targets = [
    "mobileButton",
    "desktopButton",
    "qrCodeCanvas",
    "desktopDialog",
  ];

  initialize () {
    const params = new URLSearchParams(window.location.search);
    const checkDepositTosAccepted =
      params.get("check_deposit_tos_accepted") === "true";

    if (
      !window.isUserOnMobile() &&
      (window.localStorage.getItem("triggeredQRCode") === "false" ||
        window.localStorage.getItem("triggeredQRCode") == null)
    ) {
      if (checkDepositTosAccepted) {
        this.desktopDialogTarget.show();
        window.localStorage.setItem("triggeredQRCode", "true");
      }
    } else if (window.isUserOnMobile() && checkDepositTosAccepted) {
      window.location = "/check_deposits/new";
    }
  }

  connect () {
    if (window.isUserOnMobile()) {
      this.mobileButtonTarget.hidden = false;
      if (this.hasDesktopButtonTarget) this.desktopButtonTarget.hidden = true;
    } else {
      this.generateQRCode();
      this.mobileButtonTarget.hidden = true;
      if (this.hasDesktopButtonTarget) this.desktopButtonTarget.hidden = false;
    }
  }

  generateQRCode = async () => {
    try {
      const opts = {
        type: "image/jpeg",
        width: 190,
      };

      QRCode.toCanvas(
        this.qrCodeCanvasTarget,
        window.location.origin + "/users/sign_in?check_deposit=true",
        opts,
        function (error) {
          if (error) {
            window.Honeybadger &&
              window.Honeybadger.notify(
                "Error trying to generate Check Deposit QR code: " + error,
              );
          }
        },
      );
    } catch (error) {
      console.error(error);
      window.Honeybadger &&
        window.Honeybadger.notify(
          "Error trying to generate Check Deposit QR code: " + error,
        );
    }
  };
}
