import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";
// Connects to data-controller="transaction-search"

export default class extends Controller {
  static targets = ["input", "transactionType", "fromAmount", "toAmount", "fromDate", "toDate", "apply", "form", "transactionTypeInput", "fromAmountInput", "toAmountInput", "fromDateInput", "toDateInput", "amountError", "dateError", "transactionTypePill", "transactionTypePillText", "amountPill", "amountPillText", "datePill", "datePillText", "clearAll", "resetFilters"];

  initialize () {
    this.submit = debounce(this.submit.bind(this), 300);

    // Unable to get the sl-clear event to work when binding to the input target
    // so doing this the a non-stimulus way
    const clear = document.querySelector("sl-input");
    clear.addEventListener("sl-clear", _ => {
      this.formTarget.requestSubmit();
    });
  }

  submit () {
    this.formTarget.requestSubmit();
  }

  updateTransactionType (event) {
    this.transactionTypeTarget.value = event.target.value;
    this.enableResetAll();
  }

  updateFromAmount (event) {
    this.fromAmountTarget.value = event.target.value;
    this.enableResetAll();
  }

  updateToAmount (event) {
    this.toAmountTarget.value = event.target.value;
    this.enableResetAll();
  }

  updateFromDate (event) {
    this.fromDateTarget.value = event.target.value;
    this.enableResetAll();
  }

  updateToDate (event) {
    this.toDateTarget.value = event.target.value;
    this.enableResetAll();
  }

  enableResetAll () {
    if (this.formIsEmpty()) {
      this.resetFiltersTarget.disabled = true;
      this.applyTarget.disabled = true;
    } else {
      this.resetFiltersTarget.disabled = false;
      this.applyTarget.disabled = false;
    }
  }

  formIsEmpty () {
    return !this.transactionTypeInputTarget.value &&
           !this.fromAmountInputTarget.value &&
           !this.toAmountInputTarget.value &&
           !this.fromDateInputTarget.value &&
           !this.toDateInputTarget.value;
  }

  validateInputs () {
    let valid = true;

    if (parseFloat(this.fromAmountTarget.value) > parseFloat(this.toAmountTarget.value)) {
      this.amountErrorTarget.classList.remove("hidden");
      valid = false;
    }

    if (parseInt(this.fromDateTarget.value.replace(/-/g, "")) > parseInt(this.toDateTarget.value.replace(/-/g, ""))) {
      this.dateErrorTarget.classList.remove("hidden");
      valid = false;
    }

    return valid;
  }

  removeTransactionType () {
    this.transactionTypePillTarget.classList.add("hidden");
    this.transactionTypeInputTarget.value = "";
    this.transactionTypeTarget.value = "";
    this.formTarget.requestSubmit();
    this.checkAndHideClearAll();
  }

  removeAmount () {
    this.amountPillTarget.classList.add("hidden");
    this.fromAmountInputTarget.value = "";
    this.toAmountInputTarget.value = "";
    this.fromAmountTarget.value = "";
    this.toAmountTarget.value = "";
    this.formTarget.requestSubmit();
    this.checkAndHideClearAll();
  }

  removeDate () {
    this.datePillTarget.classList.add("hidden");
    this.fromDateInputTarget.value = "";
    this.toDateInputTarget.value = "";
    this.fromDateTarget.value = "";
    this.toDateTarget.value = "";
    this.formTarget.requestSubmit();
    this.checkAndHideClearAll();
  }

  removeClearAll () {
    this.clearAllTarget.classList.add("hidden");
  }

  clearAll () {
    this.removeTransactionType();
    this.removeAmount();
    this.removeDate();
    this.removeClearAll();
  }

  applyPills () {
    if (this.transactionTypeInputTarget.value) {
      this.transactionTypePillTarget.classList.remove("hidden");
      this.clearAllTarget.classList.remove("hidden");
      this.transactionTypePillTextTarget.innerText = this.transactionTypeInputTarget.value;
    }

    if (this.fromDateInputTarget.value && this.toDateInputTarget.value) {
      this.datePillTarget.classList.remove("hidden");
      this.clearAllTarget.classList.remove("hidden");
      this.datePillTextTarget.innerText = `${this.fromDateInputTarget.value} - ${this.toDateInputTarget.value}`;
    }

    if (this.fromAmountInputTarget.value && this.toAmountInputTarget.value) {
      this.amountPillTarget.classList.remove("hidden");
      this.clearAllTarget.classList.remove("hidden");
      this.amountPillTextTarget.innerText = `$${this.fromAmountInputTarget.value} - $${this.toAmountInputTarget.value}`;
    } else {
      // if only one amount is entered show the entered amount
      if (this.fromAmountInputTarget.value) {
        this.amountPillTarget.classList.remove("hidden");
        this.clearAllTarget.classList.remove("hidden");
        this.amountPillTextTarget.innerText = `$${this.fromAmountInputTarget.value}`;
      } else if (this.toAmountInputTarget.value) {
        this.amountPillTarget.classList.remove("hidden");
        this.clearAllTarget.classList.remove("hidden");
        this.amountPillTextTarget.innerText = `$${this.toAmountInputTarget.value}`;
      }
    }
  }

  resetFilters () {
    this.transactionTypeInputTarget.value = "";
    this.fromAmountTarget.value = "";
    this.fromAmountInputTarget.value = "";
    this.toAmountTarget.value = "";
    this.toAmountInputTarget.value = "";
    this.fromDateTarget.value = "";
    this.fromDateInputTarget.value = "";
    this.toDateTarget.value = "";
    this.toDateInputTarget.value = "";
    this.amountErrorTarget.classList.add("hidden");
    this.dateErrorTarget.classList.add("hidden");
    this.removeDate();
    this.removeAmount();
    this.removeTransactionType();
    this.formTarget.requestSubmit();
    this.enableResetAll();
  }

  resetfilterErrors () {
    this.amountErrorTarget.classList.add("hidden");
    this.dateErrorTarget.classList.add("hidden");
  }

  applyFilters () {
    if (this.validateInputs()) {
      this.applyPills();
      this.formTarget.requestSubmit();
      this.resetfilterErrors();
    }
  }

  checkAndHideClearAll () {
    if (this.transactionTypePillTarget.classList.contains("hidden") &&
        this.amountPillTarget.classList.contains("hidden") &&
        this.datePillTarget.classList.contains("hidden")) {
      this.clearAllTarget.classList.add("hidden");
    }
  }
}
