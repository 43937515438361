import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["businessName", "fileUploadContent", "fileUpload", "submit"];

  connect () {
    this.enableSubmitIfCompleted();
  }

  openFileUpload () {
    this.fileUploadTarget.click();
  }

  fileSelected (event) {
    const file = event.target.files[0];
    this.fileUploadContentTarget.textContent = file.name;
    this.fileUploadTarget.dataset.completed = true;
    this.enableSubmitIfCompleted();
  }

  formCompleted () {
    return this.businessNameTarget.value && this.fileUploadTarget.dataset.completed === "true";
  }

  enableSubmitIfCompleted () {
    this.submitTarget.disabled = !this.formCompleted();
  }
}
