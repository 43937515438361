export default class Details {
  static COMPLETED_SELECTOR = "sl-icon.completed-step";
  static ACTIVE_SELECTOR = "sl-icon.active-step";
  static PROGRESS_ICON_SELECTOR = "#progress-icon";
  static PROGRESS_COMPLETED_ICON_SELECTOR = "#completed-step";
  static HIDDEN = "hidden";
  static UNSELECTED_BG_COLOR = "bg-gray-700";
  static SELECTED_BG_COLOR = "bg-blue-700";
  static UNSELECTED_TEXT_COLOR = "text-gray-800";
  static SELECTED_TEXT_COLOR = "text-blue-700";

  constructor (element) {
    this.name = element.dataset.name;
    this.element = element;
    this.activeIcon = this.element.querySelector(Details.ACTIVE_SELECTOR);
    this.progressIcon = document.querySelector(Details.PROGRESS_ICON_SELECTOR + "-" + this.name);
    this.completedIcon = this.element.querySelector(Details.COMPLETED_SELECTOR);
    this.completedProgressIcon = document.querySelector(Details.PROGRESS_COMPLETED_ICON_SELECTOR + "-" + this.name);
    this.progressText = document.querySelector("#" + this.name + "-progress-text");
  }

  setSelected (selected) {
    this.element.open = selected;

    if (selected) {
      this.setCompleted(false);
      this.setActiveIconSelected();
      this.setProgressIconSelected();
    } else {
      this.setActiveIconUnSelected();
      this.setProgressIconUnSelected();
    }
  }

  setActiveIconSelected () {
    this.activeIcon.classList.remove(Details.UNSELECTED_BG_COLOR);
    this.activeIcon.classList.add(Details.SELECTED_BG_COLOR);
  }

  setActiveIconUnSelected () {
    this.activeIcon?.classList?.add(Details.UNSELECTED_BG_COLOR);
    this.activeIcon?.classList?.remove(Details.SELECTED_BG_COLOR);
  }

  setProgressIconSelected () {
    this.progressIcon.classList.remove(Details.UNSELECTED_TEXT_COLOR);
    this.progressIcon.classList.add(Details.SELECTED_TEXT_COLOR);
    this.progressText.classList.remove(Details.UNSELECTED_TEXT_COLOR);
    this.progressText.classList.add(Details.SELECTED_TEXT_COLOR);
  }

  setProgressIconUnSelected () {
    this.progressIcon.classList.add(Details.UNSELECTED_TEXT_COLOR);
    this.progressIcon.classList.remove(Details.SELECTED_TEXT_COLOR);
    this.progressText.classList.add(Details.UNSELECTED_TEXT_COLOR);
    this.progressText.classList.remove(Details.SELECTED_TEXT_COLOR);
  }

  setCompleted (completed) {
    if (completed) {
      this.setSelected(false);
      this.setActiveIconCompleted();
      this.setProgressIconCompleted();
    } else {
      this.setActiveIconIncomplete();
      this.setProgressIconIncomplete();
    }
  }

  setActiveIconIncomplete () {
    this.completedIcon.classList.add(Details.HIDDEN);
    this.activeIcon.classList.remove(Details.HIDDEN);
  }

  setActiveIconCompleted () {
    this.completedIcon.classList.remove(Details.HIDDEN);
    this.activeIcon.classList.add(Details.HIDDEN);
  }

  setProgressIconIncomplete () {
    if (this.completedProgressIcon !== null) {
      this.completedProgressIcon.classList.add(Details.HIDDEN);
      this.progressIcon.classList.remove(Details.HIDDEN);
    }
  }

  setProgressIconCompleted () {
    if (this.completedProgressIcon !== null) {
      this.completedProgressIcon.classList.remove(Details.HIDDEN);
      this.progressIcon.classList.add(Details.HIDDEN);
    }
  }
}
